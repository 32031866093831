// components/PackageItineraryInput.js

import { useState } from 'react';

const PackageItineraryInput = ({ itinerary, setItinerary }) => {
  const addIteneryInput = () => {
    setItinerary([...itinerary, { image: '', day: '', city: '', description: '' }]);
  };

  const removeIteneryInput = (index) => {
    const updatedItinerary = [...itinerary];
    updatedItinerary.splice(index, 1);
    setItinerary(updatedItinerary);
  };

  const handleInputChange = (value, index, key) => {
    const updatedItinerary = [...itinerary];
    updatedItinerary[index][key] = value;
    setItinerary(updatedItinerary);
  };

  return (
    <div className="space-y-4">
      {itinerary.map((item, index) => (
        <div key={index} className="space-y-2">
          <input
            type="text"
            className="border rounded-md px-3 py-2 w-full"
            placeholder="Image URL"
            value={item.image}
            onChange={(e) => handleInputChange(e.target.value, index, 'image')}
          />
          <input
            type="text"
            className="border rounded-md px-3 py-2 w-full"
            placeholder="Day Number"
            value={item.day}
            onChange={(e) => handleInputChange(e.target.value, index, 'day')}
          />
          <input
            type="text"
            className="border rounded-md px-3 py-2 w-full"
            placeholder="City Detail"
            value={item.city}
            onChange={(e) => handleInputChange(e.target.value, index, 'city')}
          />
          <textarea
            className="border rounded-md px-3 py-2 w-full"
            placeholder="Description"
            rows="4"
            value={item.description}
            onChange={(e) => handleInputChange(e.target.value, index, 'description')}
          ></textarea>
          <button
            type="button"
            className="px-3 py-2 bg-red-500 text-white rounded-md"
            onClick={() => removeIteneryInput(index)}
          >
            Remove
          </button>
        </div>
      ))}
      <button
        type="button"
        className="px-3 py-2 bg-blue-500 text-white rounded-md"
        onClick={addIteneryInput}
      >
        Add
      </button>
    </div>
  );
};

export default PackageItineraryInput;
