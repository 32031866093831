import { createSlice,createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { API_BASE_URL } from "../Constant";
import { useEffect } from "react";


// const initialState = {
//     message: '',
//     user: null, // Initialize to null or appropriate initial value
//     accessToken: '', // Initialize to empty string or appropriate initial value
//     loading: false,
//     error: '',
// }

const storedState = JSON.parse(localStorage.getItem('reduxState'));
const initialState = storedState ? { ...storedState } : {
  message: '',
  user: null,
  accessToken: '',
  loading: false,
  error: '',
};

////const storedState = JSON.parse(localStorage.getItem('reduxState'));
//const defaultState = storedState ? { ...initialState, ...storedState } : initialState;

export const signUpUser = createAsyncThunk('signupuser', async (body)=>{
    const res = await fetch("hghghhg",{
        method:"post",
        headers:{
            'Content-Type':"application/json",
        },
        body:JSON.stringify(body)
    })


    
    return await res.json();
})

export const signInUser = createAsyncThunk('signInUser', async (body)=>{
    try {
        const response = await fetch(`${API_BASE_URL}/auth/login`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        });
    
        if (!response.ok) {
          throw new Error('Failed to login');
        }
    
        const data = await response.json();
        return data; // assuming your API returns `{ message, result, access_token, status }`
      } catch (error) {
        console.error('Error during login:', error.message);
        throw error; // propagate the error to be caught by Redux Toolkit
      }
})




const authSlice = createSlice({
    name:'auth',
    initialState,
    reducers:{
        addToken: (state, action) => {
            state.accessToken = localStorage.getItem('accessToken');
          },
          addUser: (state, action) => {
            state.user = localStorage.getItem('user');
          },
          logout: (state, action) => {
            state.accessToken = null;
            state.user = null;
            localStorage.clear();
          },
    },
    extraReducers: (builder) => {
        builder
          // signInUser reducers
          .addCase(signInUser.pending, (state, action) => {
            state.loading = true;
            state.error = ''; // clear previous error on pending
          })
          .addCase(signInUser.fulfilled, (state, action) => {
            state.loading = false;
            const { message, result, access_token, status } = action.payload;
            if (status === 0) {
            state.error = message;
            } else {
            state.message = message;
            state.accessToken = access_token;
            state.user = result;
            localStorage.setItem('msg', message);
            localStorage.setItem('user', JSON.stringify(result));
            localStorage.setItem('accessToken', access_token);
            }
            
          })
          .addCase(signInUser.rejected, (state, action) => {
            state.loading = true;
            state.error = 'Failed to log in'; // or action.error.message
          })
    
          // signUpUser reducers
          .addCase(signUpUser.pending, (state, action) => {
            state.loading = true;
          })
          .addCase(signUpUser.fulfilled, (state, action) => {
            state.loading = false;
            const { error, msg } = action.payload;
            if (error) {
              state.error = error;
            } else {
              state.loading = true;
            }
          })
          .addCase(signUpUser.rejected, (state, action) => {
            state.loading = true;
          });
      },

        
})

export const {addToken,addUser,logout}=authSlice.actions;
export default authSlice.reducer;