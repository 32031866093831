import React from 'react';

const InclusionInput = ({ inclusions, setInclusions }) => {
  const addInclusion = () => {
    setInclusions([...inclusions, { text: '' }]);
  };

  const removeInclusion = (index) => {
    const updatedInclusions = [...inclusions];
    updatedInclusions.splice(index, 1);
    setInclusions(updatedInclusions);
  };

  const handleInclusionChange = (value, index) => {
    const updatedInclusions = [...inclusions];
    updatedInclusions[index] = { text: value };
    setInclusions(updatedInclusions);
  };

  return (
    <div className="space-y-4">
      {inclusions.map((inclusion, index) => (
        <div key={index} className="flex items-center space-x-2">
          <input
            type="text"
            className="border rounded-md px-3 py-2 w-full"
            placeholder="Enter Inclusion"
            value={inclusion.text}
            onChange={(e) => handleInclusionChange(e.target.value, index)}
          />
          <button
            type="button"
            className="px-3 py-2 bg-red-500 text-white rounded-md"
            onClick={() => removeInclusion(index)}
          >
            Remove
          </button>
        </div>
      ))}
      <button
        type="button"
        className="px-3 py-2 bg-blue-500 text-white rounded-md"
        onClick={addInclusion}
      >
        Add
      </button>
    </div>
  );
};

export default InclusionInput;
