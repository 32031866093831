import React from 'react';

const ExclusionInput = ({ exclusions, setExclusions }) => {
  const addExclusion = () => {
    setExclusions([...exclusions, { value: '' }]);
  };

  const removeExclusion = (index) => {
    const updatedExclusions = exclusions.filter((exclusion, i) => i !== index);
    setExclusions(updatedExclusions);
  };

  const handleExclusionChange = (value, index) => {
    const updatedExclusions = exclusions.map((exclusion, i) =>
      i === index ? { ...exclusion, value } : exclusion
    );
    setExclusions(updatedExclusions);
  };

  return (
    <div className="space-y-4">
      {exclusions.map((exclusion, index) => (
        <div key={index} className="flex items-center space-x-2">
          <input
            type="text"
            className="border rounded-md px-3 py-2 w-full"
            placeholder="Enter Exclusion"
            value={exclusion.value}
            onChange={(e) => handleExclusionChange(e.target.value, index)}
          />
          <button
            type="button"
            className="px-3 py-2 bg-red-500 text-white rounded-md"
            onClick={() => removeExclusion(index)}
          >
            Remove
          </button>
        </div>
      ))}
      <button
        type="button"
        className="px-3 py-2 bg-blue-500 text-white rounded-md"
        onClick={addExclusion}
      >
        Add
      </button>
    </div>
  );
};

export default ExclusionInput;
