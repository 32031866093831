import React, {useEffect, useState } from "react";
import { useNavigate, Link ,useParams } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../../Constant";

import Sidemenu from "../../Common/Sidemenu";
import Header from "../../Common/Header";
import Footer from "../../Common/Footer";
import FAQInput from "../../Common/FAQInput";
import InclusionInput from "../../Common/Package/Inclusion";
import ExclusionInput from "../../Common/Package/Exclusion";
import PackageItineraryInput from "../../Common/Package/Itenery";


function AddPackage(){
    const [inclusions, setInclusions] = useState([]); // Initialize with one empty
    const [exclusions, setExclusions] = useState([]); // Initialize with one empty
    const [faqs, setFaqs] = useState([{ question: '', answer: '' }]); // Initialize with one empty FAQ
    const [itinerary, setItinerary] = useState([
        { image: '', day: '', city: '', description: '' },
      ]);
     
    
    const [image, setImage] = useState(null);
    const [banner, setBanner] = useState(null);
    const [selectedImages, setSelectedImages] = useState([]);
    
    const handleImageChange = (e) => {
        const file = e.target.files[0]; // Get the first file from the input
        setImage(file);
    };

    const handleBannerImageChange = (e) => {
        const file = e.target.files[0]; // Get the first file from the input
        setBanner(file);
    };
    
    const handlePackageGallery = (e) => {
        const files = Array.from(e.target.files);
        setSelectedImages(files);
    };
    
    const accessToken = localStorage.getItem("accessToken");
    const {package_id}  = useParams();
    const [PackageDetail, setPackageDetail]=useState({});
    const [LocationList, setLocationList]=useState([]);
    const [GalleryList, setGalleryList]=useState([]);
    const [selectedLocationOption, setLocationSelectedOption] = useState('');
    const [selectedPackageOption, setPackageSelectedOption] = useState('');
    const [selectedRatingOption, setRatingSelectedOption] = useState('');
    
    const fetchLocationData = async ()=>{
    {
         await axios.get(`${API_BASE_URL}/location/location-list`,{
              headers: {
                Authorization: `Bearer ${accessToken}`
              }
            }).then((response)=>{
              if(response != ''){
                if(response.data.status!=0){
                  setLocationList(response.data.result);
                  const location = response.data.result;
                 // console.log(response.data);
                }
              }
            }).catch(error => console.log(error));
        }
    }
    
    useEffect(
      ()=>{
        const getPackageDetail = async () => {
          try {    
            console.log('package_id'+package_id);                          
            const response = await axios.get(`${API_BASE_URL}/package/package-list/${package_id}`,{
              headers: {
                Authorization: `Bearer ${accessToken}`
              }
            });
            await setPackageDetail(response.data.result);
             
            if (response.data.result.location) {
              var locationbyid = response.data.result.location._id;
              setLocationSelectedOption(locationbyid);
              // console.log(response.data.result.location._id);
            }
            if (response.data.result.package_type) {
              var package_type = response.data.result.package_type;
              setPackageSelectedOption(package_type);
              // console.log(response.data.result.location._id);
            }
			if (response.data.result.rating) {
              var rating = response.data.result.rating;
              setRatingSelectedOption(rating);
            }
           
            if (response.data.result.faq) {
              var faqdata = JSON.parse(response.data.result.faq);
              setFaqs(faqdata);
            }
            if (response.data.result.include) {
              var includudion = JSON.parse(response.data.result.include);
              setInclusions(includudion);
            }
            if (response.data.result.exclude) {
              var exclusio = JSON.parse(response.data.result.exclude);
              setExclusions(exclusio);
            }

            if (response.data.result.intenery) {
                var inteneryData = JSON.parse(response.data.result.intenery);
                setItinerary(inteneryData);
              }
            
            if(response.data.result.gallery){
              var galleryLis = response.data.result.gallery;
              setGalleryList(galleryLis);
            }
            
            
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };

        fetchLocationData();
        
        if (package_id != undefined) {
          getPackageDetail();
        }
  
      },[]
    );
  
   
    //console.log(JSON.parse(PackageDetail.food)['vegeterian']);
  const handleInputChange = (e) => {
      const { name, value } = e.target;
      setPackageDetail({
        ...PackageDetail,
        [name]: value
      });
    };
   //console.log(PackageDetail);

   
   const handleLocationChange = (event) => {
    setLocationSelectedOption(event.target.value); // Update selected option when user selects a different option
  };
   const handlePackageChange = (event) => {
    setPackageSelectedOption(event.target.value); // Update selected option when user selects a different option
  };
  
  const handleRatingChange = (event) => {
    setRatingSelectedOption(event.target.value); // Update selected option when user selects a different option
  };
  
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
      //console.log(datarequest);
      try {
          
        const formDataToSend = new FormData();
        
        for (let i = 0; i < selectedImages.length; i++) {
          formDataToSend.append('gallery', selectedImages[i]);
        }
        
        formDataToSend.append('feature_image', image);
        formDataToSend.append('banner', banner);
        formDataToSend.append('package_id', package_id);
        formDataToSend.append('title', PackageDetail.title);
        formDataToSend.append('package_type', selectedPackageOption);
        formDataToSend.append('rating', selectedRatingOption);
        formDataToSend.append('content', PackageDetail.content);
        formDataToSend.append('highlight', PackageDetail.highlight);
        formDataToSend.append('youtube_video', PackageDetail.youtube_video);
        formDataToSend.append('cancellation_policy', PackageDetail.cancellation_policy);
        formDataToSend.append('min_day_for_booking', PackageDetail.min_day_for_booking);
        formDataToSend.append('duration', PackageDetail.duration);
        formDataToSend.append('price', PackageDetail.price);
        formDataToSend.append('offer_price', PackageDetail.offer_price);
        formDataToSend.append('location', selectedLocationOption);
        formDataToSend.append('tour_min_people', PackageDetail.tour_min_people);
        formDataToSend.append('tour_max_people', PackageDetail.tour_max_people);
        formDataToSend.append('real_address', PackageDetail.real_address);
        formDataToSend.append('include', JSON.stringify(inclusions));
        formDataToSend.append('exclude', JSON.stringify(exclusions));
        formDataToSend.append('intenery', JSON.stringify(itinerary));
        formDataToSend.append('faq', JSON.stringify(faqs));
        formDataToSend.append('map_zoom', PackageDetail.map_zoom);
        formDataToSend.append('search_engines_show_service', PackageDetail.search_engines_show_service);
        formDataToSend.append('seo_title', PackageDetail.seo_title);
        formDataToSend.append('seo_discription', PackageDetail.seo_discription);
        formDataToSend.append('publish', PackageDetail.publish);
        formDataToSend.append('package_available', PackageDetail.package_available);
        formDataToSend.append('phone_number', PackageDetail.phone_number);
        formDataToSend.append('email_id', PackageDetail.email_id);
        
        const response = await axios.post(`${API_BASE_URL}/package/package-register`,formDataToSend, 
          {
            headers: {
              'Content-Type': 'multipart/form-data',
               Authorization: `Bearer ${accessToken}`
            }
          }
        );
        navigate('/packages');
    }catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        console.error("Server responded with error status:", error.response.status);
        console.error("Error data:", error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        console.error("No response received from server");
      } else {
        // Something else happened while setting up the request
        console.error("Error:", error.message);
      }
  }
  
 
  }
    return(
        <>
          <Sidemenu/>
          <Header/>
          <section className="is-title-bar">
            <div className="flex flex-col md:flex-row items-center justify-between space-y-6 md:space-y-0">
                <ul>
                  <li>Admin</li>
                  <li>Package Detail Forms</li>
                </ul>
            </div>
          </section>
          <section className="is-hero-bar">
            <div className="flex flex-col md:flex-row items-center justify-between space-y-6 md:space-y-0">
                <h1 className="title">
                  Detail Form
                </h1>
                <Link to={`/packages`}>
                <button className="button blue">Back</button></Link>
            </div>
          </section>
          <section className="section main-section">
            <form onSubmit={handleSubmit}>
                <div className="flex flex-wrap">
                  <div className="w-3/4 p-1">
                      
                      <div className="card mb-6">
                        <header className="card-header">
                            <p className="card-header-title">
                              <span className="icon"><i className="mdi mdi-ballot"></i></span>
                              Package Content
                            </p>
                        </header>
                        <div className="card-content">
                            <div className="field">
								 <label className="label">Title</label>
								  <div className="control">
									  <input className="input"  name="title" type="text" placeholder="Enter Title"
										value={PackageDetail.title}
										onChange={handleInputChange} required />
								  </div>
							</div>

                           
							<div>
                                <label for="rating" className="font-bold">Select Rating</label>
                            </div>
                           <select name="rating" id="rating" value={selectedRatingOption} className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md border-2 border-#9ca3afe45"
                               onChange={handleRatingChange} >
									<option value="">Select Ratings</option>
                               		<option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                            </select>
							
							 <div>
                                <label for="package_type" className="font-bold">Select Package</label>
                            </div>
                            
							    <select name="package_type" id="package_type" value={selectedPackageOption} className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md border-2 border-#9ca3afe45"
                               onChange={handlePackageChange}>
								  <option value="">Select Package Types</option>
                             	  <option value={`Domestic`}>{`Domestic`}</option>
                                  <option value={`International`}>{`International`}</option>
                            </select>

                            <div className="field">
                              <label className="label">Content</label>
                              <div className="control">
                                  <textarea className="textarea"  name="content" type="text"
                                    value={PackageDetail.content}
                                    onChange={handleInputChange} />
                              </div>
                            </div>

                            <div className="field">
                              <label className="label">Highlight</label>
                              <div className="control">
                                  <textarea className="textarea "  name="highlight" type="text"
                                    value={PackageDetail.highlight}
                                    onChange={handleInputChange} />
                              </div>
                            </div>

                            
                        <div className="card-content">
                            <div className=" flex flex-wrap">
                              <div className="w-1/2 pr-1 sm:pr-2">
                                  <div className="field">
                                  <label className="label">Phone Number</label>
                                      <div className="control">
                                        <input className="input"  name="phone_number" type="text" placeholder="Enter Phone Number"
                                            value={PackageDetail.phone_number}
                                            onChange={handleInputChange} />
                                      </div>
                                  </div>

                                  <div className="field">
                                      <label className="label">Email Id</label>
                                      <div className="control">
                                        <input className="input" name="email_id" type="email" placeholder="Enter Email Id"
                                            value={PackageDetail.email_id}
                                            onChange={handleInputChange} />
                                      </div>
                                  </div>
                                </div>

                              <div className="w-1/2 pl-2 sm:pl-3">
                                <div className="field">
                                    <label className="label">Youtube Video</label>
                                    <div className="control">
                                        <input className="input"  name="youtube_video" type="text" placeholder="Youtube Link Video"
                                            value={PackageDetail.youtube_video}
                                            onChange={handleInputChange} />
                                    </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                </div>
            </div>

            <div className="card mb-6">
                <header className="card-header">
                    <p className="card-header-title">
                        <span className="icon"><i className="mdi mdi-ballot"></i></span>
                        Package Policy
                    </p>
                </header>
                <div className="card-content">
                    <div className="field">
                        <label className="label">Cancellation/Amendment Policy</label>
                        <div className="control">
                            <textarea className="textarea"  name="cancellation_policy" type="text" 
                                value={PackageDetail.cancellation_policy}
                                onChange={handleInputChange} />
                        </div>
                    </div>
                </div>
            </div>


            <div className="card mb-6">
                <header className="card-header">
                    <p className="card-header-title">
                        <span className="icon"><i className="mdi mdi-ballot"></i></span>
                        FAQs
                    </p>
                </header>
                <div className="card-content">
                    <div className="field">
                        <FAQInput faqs={faqs} setFaqs={setFaqs}/>
                    </div>
                </div>
            </div>  

            <div className="card mb-6">
                <header className="card-header">
                    <p className="card-header-title">
                        <span className="icon"><i className="mdi mdi-ballot"></i></span>
                        Inclusions
                    </p>
                </header>
                <div className="card-content">
                    <div className="field">
                        <InclusionInput inclusions={inclusions} setInclusions={setInclusions}/>
                    </div>
                </div>
            </div> 

            <div className="card mb-6">
                <header className="card-header">
                    <p className="card-header-title">
                        <span className="icon"><i className="mdi mdi-ballot"></i></span>
                        Exclusion
                    </p>
                </header>
                <div className="card-content">
                    <div className="field">
                        <ExclusionInput exclusions={exclusions} setExclusions={setExclusions}/>
                    </div>
                </div>
            </div>                
            
            <div className="card mb-6">
                <header className="card-header">
                    <p className="card-header-title">
                        <span className="icon"><i className="mdi mdi-ballot"></i></span>
                        Itenery
                    </p>
                </header>
                <div className="card-content">
                    <div className="field">
                        <PackageItineraryInput itinerary={itinerary} setItinerary={setItinerary} />
                    </div>
                </div>
            </div>                
                           


                        <div className="card mb-6">
                            <header className="card-header">
                              <p className="card-header-title">
                                  <span className="icon"><i className="mdi mdi-ballot"></i></span>
                                  Booking Policy
                              </p>
                            </header>
                            <div className="card-content">
                            <div className=" flex flex-wrap">
                              <div className="w-1/2">
                                  <div className="field">
                                    <label className="label"> Min day For booking </label>
                                    <div className="control">
                                        <input  className="input" type="number"  name="min_day_for_booking" min="1" max="100" placeholder="Ex: 2"
                                          value={PackageDetail.min_day_for_booking}
                                          onChange={handleInputChange}  />
                                    </div>
                                  </div>
                              </div>
                              <div className="w-1/2 pl-1 sm:pl-2">
                                  <div className="field">
                                    <label className="label"> Duration </label>
                                    <div className="control">
                                        <input  className="input" type="text"  name="duration" min="1" max="100" placeholder="Ex: 2"
                                          value={PackageDetail.duration}
                                          onChange={handleInputChange}  />
                                    </div>
                                  </div>
                              </div>

                              <div className="w-1/2">
                                  <div className="field">
                                    <label className="label"> Tour Min People </label>
                                    <div className="control">
                                        <input  className="input" type="string"  name="tour_min_people" min="1" max="100" placeholder="Ex: 2"
                                          value={PackageDetail.tour_min_people}
                                          onChange={handleInputChange}  />
                                    </div>
                                  </div>
                              </div>
                              <div className="w-1/2 pl-1 sm:pl-2">
                                  <div className="field">
                                    <label className="label"> Tour Max People </label>
                                    <div className="control">
                                        <input  className="input" type="string"  name="tour_max_people" min="1" max="100" placeholder="Ex: 2"
                                          value={PackageDetail.tour_max_people}
                                          onChange={handleInputChange}  />
                                    </div>
                                  </div>
                              </div>
                            </div>
                        </div>
                      </div>

                        <div className="card mb-6">
                            <header className="card-header">
                              <p className="card-header-title">
                                  <span className="icon"><i className="mdi mdi-ballot"></i></span>
                                  Pricing
                              </p>
                            </header>
                            <div className="card-content">
                            <div className="field">
                              <label className="label"> Price </label>
                              <div className="control">
                                  <input  className="input" type="number"  name="price" min="0" max="100000" 
                                    value={PackageDetail.price}
                                    onChange={handleInputChange}  />
                              </div>  
                            </div>

                            <div className="field">
                              <label className="label">Offer Price </label>
                              <div className="control">
                                  <input  className="input" type="number"  name="offer_price" min="0" max="100000" 
                                    value={PackageDetail.offer_price}
                                    onChange={handleInputChange}  />
                              </div>
                            </div> 
                        </div>
                        </div>

                        <div className="card mb-6">
                            <header className="card-header">
                              <p className="card-header-title">
                                  <span className="icon"><i className="mdi mdi-ballot"></i></span>
                                  Locations
                              </p>
                            </header>
                            <div className="card-content">
                       
                            <div>
                                <label for="location" className="font-bold">Select Location</label>
                            </div>
                            <select name="location_name" id="location" value={selectedLocationOption} className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md border-2 border-#9ca3afe45"
                            
                             onChange={handleLocationChange} >
                               <option value="">Select Location List</option>
                               {LocationList && LocationList.map((value, key) => (
                                  <option key={key} value={value._id}>
                                    {value.location_name}
                                  </option>
                                ))}
                               
                            </select>


                        <div className="field">
                            <label className="label">Real Address</label>
                            <div className="control">
                              <input className="input"  name="real_address" type="text" placeholder="Real Address"
                                  value={PackageDetail.real_address}
                                  onChange={handleInputChange} />
                            </div>
                        </div>
                        
                        <div className="field">
                            <label className="label">The Geographic Map</label>
                            <div className="control">
                              <textarea className="textarea"  name="map_zoom" type="text" placeholder="Search by name..."
                                  value={PackageDetail.map_zoom}
                                  onChange={handleInputChange} />
                            </div>
                        </div>
                   
                    </div>
                    </div>

                    <div className="card mb-6">
                <header className="card-header">
                    <p className="card-header-title">
                        <span className="icon"><i className="mdi mdi-ballot"></i></span>
                        Itenery
                    </p>
                </header>
                <div className="card-content">
                        <div className="field">
                            <label className="label">Gallery</label>
                            <div className="control">
                                <input type="file" id="gallery" name="gallery[]" className=" hidden" onChange={handlePackageGallery} multiple/>
                                <label for="gallery" className="  bg-blue-500 text-white px-2 sm:px-3 py-1 sm:py-2 rounded"> 
                                <i className="mdi mdi-plus"></i> Select Images</label>
                            </div>

                            {
                                GalleryList.map((val,key) => {
                                return(
                                    <img src={`${API_BASE_URL}/package/${val}`} className="my-4 sm:my-6 w-40 h-40 inline-block mr-2" />
                                )
                                })
                                
                            }
                        </div>
                    </div>
                </div> 

                    <div className="card mb-6">
                            <header className="card-header">
                              <p className="card-header-title">
                                  <span className="icon"><i className="mdi mdi-ballot"></i></span>
                                  Seo Manager
                              </p>
                            </header>
                            <div className="card-content">
                            <div className="field">
                                <label className="label">Allow search engines to show this service in search results?</label>
                                <div className="control">
                                      <input type="radio" name="search_engines_show_service" className="mx-5"  
                                                value='yes'
                                                onChange={handleInputChange}  checked={PackageDetail.search_engines_show_service=="yes"?true:false}/>
                                            <label >Yes</label><br/>
                                        <input type="radio" name="search_engines_show_service" className="mx-5" 
                                                value='no'
                                                checked={PackageDetail.search_engines_show_service=="no"?true:false} onChange={handleInputChange}/>
                                            <label >No</label>
                                </div>
                            </div>

                            <div className="field">
                            <label className="label">Seo Title</label>
                            <div className="control">
                              <input className="input"  name="seo_title" type="text" placeholder="Leave blank to use service title"
                                  value={PackageDetail.seo_title}
                                  onChange={handleInputChange} />
                            </div>
                        </div>

                        <div className="field">
                            <label className="label">Seo Discription</label>
                            <div className="control">
                              <textarea className="textarea"  name="seo_discription" type="text" placeholder="Enter discription"
                                  value={PackageDetail.seo_discription}
                                  onChange={handleInputChange} />
                                  
                            </div>
                        </div>
                     </div>
                   </div>  
                       
                        
            
                    <div className="card mb-6">
                        <div className="card-content">
                            <div className="control">
                            <button type="submit" className="bg-green-700 text-white px-2 sm:px-3  py-1 sm:py-2 rounded">
                            Submit
                            </button>
                            </div> 
                        </div>
                    </div>


                  </div>
                  
                
                  <div className="w-1/4 p-1">

                  <div className="card mb-6">
                    <div className="card-content">
                        <div className="control">
                          <input type="submit" id="save_changes"  className=" hidden"/>
                          <label for="save_changes" className=" bg-green-700 text-white px-2 sm:px-3 py-1 sm:py-2 rounded"> <i className="mdi mdi-ballot"></i> Save Changes</label>
                        </div>
                    </div>
                  </div>
                    <div className="card mb-6">
                        <header className="card-header">
                          <p className="card-header-title">
                              <span className="icon"><i className="mdi mdi-ballot"></i></span>
                              Publish
                          </p>
                        </header>
                        <div className="card-content">
                              <div className="control">
                                        <input type="radio" className="mx-5" name="publish" 
                                            value='Publish'
                                            checked={PackageDetail.publish=="Publish"?true:false}
                                            onChange={handleInputChange}/>
                                        <label>publish</label><br/>

                                        <input type="radio" className="mx-5" name="publish" 
                                            value='Draft'
                                            checked={PackageDetail.publish=="Draft"?true:false}
                                            onChange={handleInputChange}/>
                                        <label>Draft</label><br></br><br></br>

                                       
                              </div>
                        </div>
                    </div>
                   

                    <div className="card mb-6">
                        <header className="card-header">
                          <p className="card-header-title">
                              <span className="icon"><i className="mdi mdi-ballot"></i></span>
                              Set Availability
                          </p>
                        </header>
                        <div className="card-content">
                        <div className="field">
                            <label className="label">Available Package</label>
                            <div className="control">
                                <input type="radio" name="package_available" className="mx-5"  
                                        value='yes'
                                        onChange={handleInputChange}  checked={PackageDetail.package_available=="yes"?true:false}/>
                                    <label >Yes</label><br/>
                                <input type="radio" name="package_available" className="mx-5" 
                                        value='no'
                                        checked={PackageDetail.package_available=="no"?true:false} onChange={handleInputChange}/>
                                    <label >No</label>
                               
                            </div>
                        </div>
                      </div>
                    </div>

                    <div className="card mb-6">
                        <header className="card-header">
                          <p className="card-header-title">
                              <span className="icon"><i className="mdi mdi-ballot"></i></span>
                              Feature Image
                          </p>
                        </header>

                        <div className="card-content">
                            <div className="field">
                              <div className="control">
                                  <input name="feature_image" id="feature_image" type="file" onChange={handleImageChange} className="hidden"/>
                                  <label for="feature_image" className="  bg-blue-500 text-white px-2 sm:px-3 py-1 sm:py-2 rounded"> <i className="mdi mdi-plus"></i> Upload Image</label>
                              </div>
                            </div>
                            <img src={`${API_BASE_URL}/package/${PackageDetail.feature_image}`} className="my-4 sm:my-6" />
                        </div>
                    </div>


                    <div className="card mb-6">
                        <header className="card-header">
                          <p className="card-header-title">
                              <span className="icon"><i className="mdi mdi-ballot"></i></span>
                              Banner Image
                          </p>
                        </header>

                        <div className="card-content">
                            <div className="field">
                              <div className="control">
                                  <input name="banner" id="banner" type="file" onChange={handleBannerImageChange} className="hidden"/>
                                  <label for="banner" className="bg-blue-500 text-white px-2 sm:px-3 py-1 sm:py-2 rounded"> <i className="mdi mdi-plus"></i> Upload Image</label>
                              </div>
                            </div>
                            <img src={`${API_BASE_URL}/package/${PackageDetail.banner}`} className="my-4 sm:my-6" />
                        </div>
                    </div>
                </div>
              </div>
                
            </form>
        </section>

        
    
          <Footer/>

        </>
    );
}
export default AddPackage;