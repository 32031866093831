import { configureStore } from "@reduxjs/toolkit"
import authSlice from "./redux/authSlice";


const persistedState = localStorage.getItem('reduxState')
  ? JSON.parse(localStorage.getItem('reduxState'))
  : {};

const store = configureStore({
    reducer:{
        user: authSlice,
    }, 
    preloadedState: persistedState, // Set preloadedState with persisted state

});

  store.subscribe(() => {
    localStorage.setItem('reduxState', JSON.stringify(store.getState()));
  });

export default store;
