
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";

import Footer from "../../Common/Footer";
import Header from "../../Common/Header";
import Sidemenu from "../../Common/Sidemenu";
import { API_BASE_URL } from "../../Constant";



 function Enquerieslist(){
  const accessToken = localStorage.getItem("accessToken");
  
  const [CollectEnquery, RealData] = useState([]);
  const [deleted, setDeleted] = useState(false);

    useEffect(()=>{
      fetchEnqueryData()
  },[]);

  
   
    const isDataEmpty = () => {
      return CollectEnquery.length === 0;
    };
    const navigate = useNavigate();
    const handleDelete = async (id) => {
        try {
                                                  
          const response = await axios.delete(`${API_BASE_URL}/enquery/enquery-delete/${id}`,{
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          });

          fetchEnqueryData();
          console.log('Deleted:', response.data.result);
          setDeleted(true); // Optionally, update state or perform any other action after successful deletion
          navigate('/enqueries');
      } catch (error) {
      console.error('Error deleting:', error);
      // Handle error, show a message, etc.
    } 
  };




 const fetchEnqueryData = async ()=>{
  {
     await axios.get(`${API_BASE_URL}/enquery/enquery-list`,{
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }).then((response)=>{
          if(response != ''){
            if(response.data.status!=0){
              RealData(response.data.result);
              const enquery = response.data.result;
              console.log(response.data);
            }
          }
        }).catch(error => console.log(error));
    }
}


       


    return(
     <>
        <Sidemenu/>
        <Header/>
        

        <section className="is-title-bar">
  <div className="flex flex-col md:flex-row items-center justify-between space-y-6 md:space-y-0">
    <ul>
        
      <li><a href={`/`}>Admin</a></li>
      <li>Enquery Listing</li>
    </ul>
    
  </div>
</section>
        
        <section className="is-hero-bar">
            <div className="flex flex-col md:flex-row items-center justify-between space-y-6 md:space-y-0">
                <h1 className="title">
                    Enquery List
                </h1>
            </div>
       </section>



        <section className="section main-section">
          
        {isDataEmpty() ? (
           <div className="notification red">
             {deleted && <p>Document deleted successfully!</p>}
            
           <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0">
             <div>
               <span className="icon"><i className="mdi mdi-buffer"></i></span>
               <b>Empty table.</b>
             </div>
             <button type="button" className="button small textual --jb-notification-dismiss">Dismiss</button>
           </div>
         </div>

):(
    <div className="notification blue">
       {deleted && <p>Document deleted successfully!</p>}
      <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0">
        <div>
          <span className="icon"><i className="mdi mdi-buffer"></i></span>
          <b>Enquery</b>
        </div>
        <button type="button" className="button small textual --jb-notification-dismiss">Dismiss</button>
      </div>
    </div>
       )}
    <div className="card has-table">
       {deleted && <p>Document deleted successfully!</p>}
      <header className="card-header">
        <p className="card-header-title">
          Enquery
        </p>
        <a href="#" className="card-header-icon">
          <span className="icon"><i className="mdi mdi-reload"></i></span>
        </a>
      </header>
      <div className="card-content">
        <table>
          <thead>
          <tr>
           
            
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Check In</th>
            <th>Check Out</th>
            <th>Adults</th>
            <th>Message</th>
           
          </tr>
          </thead>
          <tbody>
          {
          CollectEnquery.map((value,key) => {
            return(
              <tr>
            
            <td data-label="Name">{value.name}</td>
            <td data-label="Email">{value.email}</td>
            <td data-label="Phone">{value.phone} </td>
            <td data-label="Checkin">{value.checkIn} </td>
            <td data-label="Checkouts">{value.checkOut} </td>
            <td data-label="Adults">{value.adults} </td>
            <td data-label="Adults">{value.message} </td>
            <td className="actions-cell">
              <div className="buttons right nowrap">
                <button className="button small red --jb-modal" data-target="sample-modal" type="button" onClick={() => handleDelete(value._id)}>
                  <span className="icon"><i className="mdi mdi-trash-can"></i></span>
                </button>
              </div>
            </td>
          </tr>


            )

          })

          }
          
          
          
          
         
          </tbody>
        </table>
        <div className="table-pagination">
          <div className="flex items-center justify-between">
            <div className="buttons">
              <button type="button" className="button active">1</button>
              <button type="button" className="button">2</button>
              <button type="button" className="button">3</button>
            </div>
            <small>Page 1 of 3</small>
          </div>
        </div>
      </div>
    </div>

   

    <div className="card empty">
      <div className="card-content">
        <div>
          <span className="icon large"><i className="mdi mdi-emoticon-sad mdi-48px"></i></span>
        </div>
        <p>Nothing's here…</p>
      </div>
    </div>
  </section>

 <Footer/>

</>  
);
}
export default Enquerieslist;       
        

