import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { signInUser } from '../redux/authSlice';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(username, password);
    
    dispatch(signInUser({ username, password }))
      .unwrap()
      .then((response) => {
        // Handle successful login
        console.log('Login successful:', response);
        navigate('/'); // Navigate to homepage or desired route
      })
      .catch((error) => {
        // Handle login error
        console.error('Login failed:', error);
        setError('Login failed. Please check your credentials.');
      });
  };

  return (
    <div className="form-screen">
      <div width="100%">
        <section className="section main-section">
          <div className="card">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon"><i className="mdi mdi-lock"></i></span>
                Login
              </p>
            </header>
            <div className="card-content">
              <form onSubmit={handleSubmit}>
                <div className="field spaced">
                  <label className="label">Login</label>
                  <div className="control icons-left">
                    <input
                      className="input"
                      type="text"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                    <span className="icon is-small left"><i className="mdi mdi-account"></i></span>
                  </div>
                  <p className="help">Please enter your login</p>
                </div>

                <div className="field spaced">
                  <label className="label">Password</label>
                  <p className="control icons-left">
                    <input
                      className="input"
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <span className="icon is-small left"><i className="mdi mdi-asterisk"></i></span>
                  </p>
                  <p className="help">Please enter your password</p>
                </div>

                <hr />

                <div className="field grouped">
                  <div className="control">
                    {error && <p>{error}</p>}
                    <button type="submit" className="button blue">Login</button>
                  </div>
                  <div className="control">
                    <a href="index.html" className="button">Back</a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Login;
