// components/FAQInput.js

import { useState } from 'react';

const FAQInput = ({ faqs, setFaqs }) => {
  const addInput = () => {
    setFaqs([...faqs, { question: '', answer: '' }]);
  };
  console.log(faqs)
  const removeInput = (index) => {
    const updatedFaqs = [...faqs];
    updatedFaqs.splice(index, 1);
    setFaqs(updatedFaqs);
  };

  const handleInputChange = (value, index, key) => {
    const updatedFaqs = [...faqs];
    updatedFaqs[index][key] = value;
    setFaqs(updatedFaqs);
  };

  return (
    <div className="space-y-4">
      {faqs.map((faq, index) => (
        <div key={index} className="space-y-2">
          <input
            type="text"
            className="border rounded-md px-3 py-2 w-full"
            placeholder="Enter Question"
            value={faq.question}
            onChange={(e) => handleInputChange(e.target.value, index, 'question')}
          />
          <textarea
            className="border rounded-md px-3 py-2 w-full"
            placeholder="Enter Answer"
            rows="4"
            value={faq.answer}
            onChange={(e) => handleInputChange(e.target.value, index, 'answer')}
          ></textarea>
          <button
            type="button"
            className="px-3 py-2 bg-red-500 text-white rounded-md"
            onClick={() => removeInput(index)}
          >
            Remove
          </button>
        </div>
      ))}
      <button
        type="button"
        className="px-3 py-2 bg-blue-500 text-white rounded-md"
        onClick={addInput}
      >
        Add
      </button>
    </div>
  );
};

export default FAQInput;
